import * as React from 'react';
import { render } from 'react-dom'
import App from './App';

const DEFAULT_LOCALE = 'en-US';

export default (element, config) => {
    const locale          = config?.locale ?? DEFAULT_LOCALE;
    const translations    = config?.translations ?? {};
    const helpUrl         = config?.helpUrl ?? '/';
    const tosUrl          = config?.tosUrl ?? '/';
    const privacyUrl      = config?.privacyUrl ?? '/';
    const personalInfoUrl = config?.personalInfoUrl ?? '/';
    render(<App
            locale={locale}
            translations={translations}
            helpUrl={helpUrl}
            tosUrl={tosUrl}
            privacyUrl={privacyUrl}
            personalInfoUrl={personalInfoUrl}
           />,
    element)
}
