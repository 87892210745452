import * as React from 'react';

export default props => {
    return (
        <div className="search-result-message" >
            {!!props.title && <div className="text-md-bold">{props.title}</div>}
            {!!props.message && <div className="text-sm">{props.message}</div>}
        </div>
    );
}
