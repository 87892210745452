import * as React from 'react';
import CircleIcon from './CircleIcon';

export default props => {
    return (
        <div className="shaded-content">
            <div className="shaded-content-icon-container">
                <CircleIcon iconClass={props.iconClass} />
            </div>
            <div className="shaded-content-content-container">
                {props.children}
            </div>
        </div>
    );
}
