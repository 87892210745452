export type GAEventProperties = {
    event_category?: string;
    event_value?: string;
};

export enum GAEventName {
    Search = 'search_event_name',
    SearchEmpty = 'empty_event_name',
    GetHelp = 'help_event_name',
}

export const gaEvent = <T extends GAEventProperties>(eventName: GAEventName, eventProperties?: T) => {
    if (!window.gtag) {
        console.info('[GA] "gtag" is not defined.');
        return;
    }

    window.gtag('event', eventName, eventProperties);
};
