import { weekDayMonthDayTimeShort, weekDayMonthDayTimeRange } from '../../sc-intl/dist/dateTimeFormatters';
import { isValidTimezoneString } from '../../shared/util/dates';

/**
 * Get the distance from the bottom of an element to the bottom of the screen
 */
export const getDistanceToBottom = element => {
    const rect = element.getBoundingClientRect();
    return window.innerHeight - rect.bottom;
}

/**
 * Get the date or date range to display from the event start and end dates
 */
export const formatEventDate = (locale, start, end = null, tz = null): string => {
    let formatted = '';
    const useTz = isValidTimezoneString(tz) ? tz : null;
    if (start) {
        try {
            formatted =  end
                ? weekDayMonthDayTimeRange(locale, new Date(start), new Date(end), tz)
                : weekDayMonthDayTimeShort(locale, new Date(start), tz);
        } catch (err) {
            console.log("error formatting date", err);
        }
    }
    return formatted;
}

/**
 * Get the location string to display from the venue city and venue state
 */
export const formatLocation = (city, state): string => {
    const locParts = [];
    if (city && typeof city === 'string') {
        locParts.push(city);
    }
    if (state && typeof state === 'string') {
        locParts.push(state);
    }
    return locParts.join(', ');
}
