import * as React from 'react';
import EventLink from './EventLink';

export default () => {
    return (<React.Fragment>
        <EventLink loading={true} />
        <EventLink loading={true} />
        <EventLink loading={true} />
    </React.Fragment>)
}
